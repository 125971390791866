import i18n from '@/locales'

export enum InvoiceStatus {
  ALL = 'all',
  DRAFT = 'draft',
  SENT = 'sent',
  PAYMENT_PROCESSING = 'payment_processing',
  PAID = 'paid',
  VOID = 'void',
  PARTIALLY_PAID = 'partially_paid',
}

export enum OrderStatus {
  PENDING = 'pending',
  COMPLETED = 'completed',
  CANCELLED = 'cancelled',
}

export enum PaymentStatus {
  PAID = 'paid',
  UNPAID = 'unpaid',
  REFUNDED = 'refunded',
}

export enum SubscriptionStatus {
  TRIALING = 'trialing',
  INCOMPLETE = 'incomplete',
  INCOMPLETE_EXPIRED = 'incomplete_expired',
  ACTIVE = 'active',
  PAST_DUE = 'past_due',
  CANCELED = 'canceled',
  UNPAID = 'unpaid',
  APPROVAL_PENDING = 'approval_pending',
  APPROVED = 'approved',
  SUSPENDED = 'suspended',
  EXPIRED = 'expired',
  PENDING = 'pending',
  PAUSED = 'paused',
  SCHEDULED = 'scheduled',
}

export enum SubscriptionStatusDisplay {
  trialing = i18n.global.t('status.trialing'),
  incomplete = i18n.global.t('status.incomplete'),
  incomplete_expired = i18n.global.t('status.incompleteExpired'),
  active = i18n.global.t('status.active'),
  past_due = i18n.global.t('status.pastDue'),
  canceled = i18n.global.t('status.canceled'),
  unpaid = i18n.global.t('status.unpaid'),
  approval_pending = i18n.global.t('status.approvalPending'),
  approved = i18n.global.t('status.approved'),
  suspended = i18n.global.t('status.suspended'),
  expired = i18n.global.t('status.expired'),
  pending = i18n.global.t('status.pending'),
  paused = i18n.global.t('status.paused'),
  scheduled = i18n.global.t('status.scheduled'),
}

export enum TransactionStatus {
  PENDING = 'pending',
  PROCESSING = 'processing',
  SUCCEEDED = 'succeeded',
  FAILED = 'failed',
  PARTIALLY_REFUNDED = 'partially_refunded',
  REFUNDED = 'refunded',
  CANCELLED = 'cancelled',
}

export enum TransactionStatusDisplay {
  pending = i18n.global.t('status.pending'),
  processing = i18n.global.t('status.processing'),
  succeeded = i18n.global.t('status.succeeded'),
  failed = i18n.global.t('status.failed'),
  partially_refunded = i18n.global.t('status.partiallyRefunded'),
  refunded = i18n.global.t('status.refunded'),
  cancelled = i18n.global.t('status.cancelled'),
}

export enum RefundStatus {
  PENDING = 'pending',
  SUCCEEDED = 'succeeded',
  FAILED = 'failed',
}

export enum RefundStatusDisplay {
  pending = i18n.global.t('status.pending'),
  succeeded = i18n.global.t('status.succeeded'),
  failed = i18n.global.t('status.failed'),
}

export const PriceTypes = {
  recurring: 'Recurring',
  one_time: 'Onetime',
  onetime: 'Onetime',
}

export const OrderSourceType = {
  funnel: i18n.global.t('common.funnel'),
  website: i18n.global.t('common.website'),
  invoice: i18n.global.t('common.invoice'),
  calendar: i18n.global.t('common.calendar'),
  text2Pay: i18n.global.t('common.text2Pay'),
  membership: i18n.global.t('common.membership'),
  manual: i18n.global.t('common.manualPayment'),
  form: i18n.global.t('common.form'),
  survey: i18n.global.t('common.survey'),
  payment_link: i18n.global.t('common.payment_link'),
  external: i18n.global.t('common.external'),
}

export const OrderSourceSubType = {
  order_form: i18n.global.t('common.orderForm'),
  one_step_order_form: i18n.global.t('common.oneStepOrderForm'),
  two_step_order_form: i18n.global.t('common.twoStepOrderForm'),
  upsell: i18n.global.t('common.upsell'),
  text2Pay: i18n.global.t('common.text2Pay'),
  store: i18n.global.t('common.store'),
  contact_view: i18n.global.t('common.contactView'),
  shopify: 'Shopify',
  document_contracts: i18n.global.t('common.document_contracts'),
  mobile_pos: i18n.global.t('common.mobile_pos'),
  calendar: i18n.global.t('common.calendar'),
  store_upsell: i18n.global.t('common.storeUpsell'),
}

export const PaymentProvider = {
  stripe: i18n.global.t('common.stripe'),
  paypal: i18n.global.t('common.paypal'),
  manual: i18n.global.t('common.manual'),
  'authorize-net': i18n.global.t('common.authorizeNet'),
  nmi: i18n.global.t('common.nmi'),
  external: i18n.global.t('common.external'),
  square: i18n.global.t('common.square'),
}

export enum ManualPaymentMode {
  Cash = 'cash',
  Card = 'card',
  Cheque = 'cheque',
  'Bank Transfer' = 'bank_transfer',
  Other = 'other',
}
export enum PaymentMethodType {
  US_BANK_ACCOUNT = 'us_bank_account',
}
export const TYPE_TO_SKIP_EXPIRY = [PaymentMethodType.US_BANK_ACCOUNT]

export enum ManualPaymentModeDisplay {
  cash = i18n.global.t('common.cash'),
  card = i18n.global.t('common.card'),
  cheque = i18n.global.t('common.cheque'),
  bank_transfer = i18n.global.t('common.bankTransfer'),
  other = i18n.global.t('common.other'),
}

export enum CardType {
  Visa = 'visa',
  Mastercard = 'mastercard',
  Other = 'other',
}

export enum CardTypeDisplay {
  visa = i18n.global.t('common.visa'),
  mastercard = i18n.global.t('common.mastercard'),
  other = i18n.global.t('common.other'),
}

export enum DiscountType {
  PERCENTAGE = 'percentage',
  AMOUNT = 'amount',
  FIXED = 'fixed',
}

export enum AltType {
  LOCATION = 'location',
}

export enum IntervalType {
  YEARLY = 'yearly',
  MONTHLY = 'monthly',
  WEEKLY = 'weekly',
  DAILY = 'daily',
  HOURLY = 'hourly',
  MINUTELY = 'minutely',
}

export enum TaxCalculation {
  EXCLUSIVE = 'exclusive',
}

export enum CouponStatus {
  SCHEDULED = 'scheduled',
  ACTIVE = 'active',
  EXPIRED = 'expired',
}

export enum OrderFulfillmentStatus {
  UNFULFILLED = 'unfulfilled',
  FULFILLED = 'fulfilled',
  PARTIALLY_FULFILLED = 'partially_fulfilled',
}

export enum EmailStatus {
  SENT = 1,
  NOT_SENT = 0,
}

export enum RecoveryStatus {
  RECOVERED = 1,
  NOT_RECOVERED = 0,
}

export const renderEmailStatus = (emailStatus: EmailStatus) => {
  return emailStatus === EmailStatus.SENT
    ? i18n.global.t('status.sent')
    : i18n.global.t('common.notSent')
}

export const DEFAULT_PAGINATION = {
  skip: 0,
  limit: 30,
  total: 0,
}

export const STRIPE_CARD_ONLY_TEST_PMC = 'pmc_1PVvmcFpU9DlKp7RFsjX5G7G'
export const STRIPE_CARD_ONLY_LIVE_PMC = 'pmc_1PVvogFpU9DlKp7Rj8F0ITLV'
export const SAAS_EVENTS = {
  FEATURES_UPDATE: 'features-update',
  CREDITS_UPDATE: 'credits-update',
  CREATE_REBILLING_CONFIG: 'create-rebilling-config',
  REBILLING_UPDATE: 'rebilling-update',
  CREATE_CATEGORY: 'create-category',
  CATEGORY_UPDATE: 'category-update',
  EDIT_CONTACT_LIMIT: 'edit-contact-limit',
  EDIT_USER_LIMIT: 'edit-user-limit',
}

export const REBILLING_PRODUCTS = {
  PHONE_SYSTEM: 'Phone System',
}

export const SAAS_INTERNAL_AGENCIES = [
  'YuTUZlUtrwBtvmgByZDW',
  'Ydk7Yx1VsHhacT39iE4Z',
  '5DP4iH6HLkQsiKESj6rh',
  '4jmzV68Y7eDq2tFXlpva',
  'w6dADQRzRL23SiVdK5VL',
  'fvFz4JDyf30Vq2rDtkdA',
]

export const DEFAULT_INVOICE_PREFIX = 'INV-'
